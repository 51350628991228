.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.indent{
  text-indent: 4%;
}

.imgcircle{
  border-radius: 20%;
}

.point{
  cursor: pointer;
}

.cro-container {
  position: relative;
}
.cro-text {
  position: absolute;
  bottom: 8px;
  left: 16px;
}
.cro-center{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.padcro{
  padding-left: 8%;
  padding-right: 8%;
}

.appbaredge{
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.swal2-container {
  z-index: 3000 !important;
  width: 100vw !important;
}

.map-container {
  height: 600px;
  }

  .MuiPaginationItem-textSecondary{
    color: white !important
  }

  .darkdialog .MuiDialog-container .MuiPaper-root {
    background-color: #02012b;
  }


  .forcebgPrimary{
    background-color: #1976d2 !important;
  }

  .nl {
    white-space: pre-line
  }

  .rounded-im{
    border-radius: 10%;
  }

  .img-zoom {
    overflow: hidden; /* [1.2] Hide the overflowing of child elements */
  }
  
  /* [2] Transition property for smooth transformation of images */
  .img-zoom img {
    transition: transform .5s ease;
  }
  
  /* [3] Finally, transforming the image when container gets hovered */
  .img-zoom:hover img {
    transform: scale(1.3);
  }

  @media only screen and (max-width: 800px) {
    .img-zoom:hover img {
      transform: scale(1);
    }
  }

  .customfloat{
    position: sticky !important;
    top: 70px !important;
    z-index: 500 !important;
  }

  .Loadend { animation: fadeIn 2s !important; }
  .Loadstart { animation: fadeOut 1.5s !important; }

  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  @keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }

  .changemodedark > .MuiSwitch-track{
    background-color: rgb(199, 199, 199) !important;
  }
  .changemodedark > .MuiButtonBase-root > .MuiSwitch-thumb{
    color: #fff !important;
  }

  .changemode > .MuiButtonBase-root > .MuiSwitch-thumb{
    color: #1976d2 !important;
  }

  div[class*="SeeMoreComponent-styles"]:has(.darkdialog) {
    background: #020147;
  }
  .darkrte > #mui-rte-root >#mui-rte-container>#mui-rte-toolbar> button {
    color: #fff;
  }
  .darkrte > #mui-rte-root >#mui-rte-container>#mui-rte-editor>#mui-rte-editor-container>.DraftEditor-root>
  .DraftEditor-editorContainer>.public-DraftEditor-content>div>div>div>span>span {
    color: #fff;
  }
  .darkrte > #mui-rte-root >#mui-rte-container>#mui-rte-editor>#mui-rte-editor-container>.DraftEditor-root>
  .DraftEditor-editorContainer>.public-DraftEditor-content>div>div>div {
    color: #fff;
  }

  .darkchip > svg{
    color: white !important;
  }

  .ptr-element .pullrefload {
    display: inline;
  }
  .ptr-refresh .ptr-element .pullrefload {
    display: inline;
  }
  .ptr-loading .ptr-element .pullrefload,
.ptr-reset .ptr-element .pullrefload {
  display: none;
}


  
  