body {
  margin: 0;
  font-family: "misans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  text-decoration: none;
  color: rgb(46, 46, 46);
}
.lightlink a {
  text-decoration: none;
  color: white;
}
a:hover{
  text-decoration: none;
  color: rgb(73, 73, 73);
}

.lightlink a:hover{
  text-decoration: none;
  color: #1976d2;
}
.text-light a {
  text-decoration: none;
  color: white;
}